import { Link } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import "../scss/termsofuse.scss";

const PrivacyPolicy = () => {
    return (
        <Layout title="Cookies Policy">
            <div className="">
                <div className="termsBg">
                    <h1>Cookies Policy</h1>
                </div>
                <div className="cookiesPolicy">
                    <p>
                        Tyson Foods, Inc., its affiliates and non-affiliated third parties (collectively, “<strong>we</strong>” or “<strong>us</strong>”), collect personal
                        information about users over time and across different Tyson websites, applications, platforms and devices (collectively, the “<strong>Site(s)</strong>”).
                        This Cookies Policy describes how you can exercise your rights and <a href="#HowtoControlTrackingTools">controls</a> over our use of Tracking Tools.
                    </p>

                    <h5>What Are Tracking Tools</h5>

                    <p>
                        We use cookies and similar technologies, such as web beacons, pixel tags and flash objects (“<strong>Tracking Tools</strong>”).
                        <ul>
                            <li>
                                <u>Cookies</u>: A cookie is a small text file that may be placed on your device when you visit our Sites. When you next visit our Sites, the cookie
                                allows us to distinguish your device from other users’ devices. These cookies are placed by us and selected third parties and enable advertisements
                                to be presented on our and third-party websites.
                            </li>
                            <li>
                                <u>Web Beacons or Pixel Tags</u>: A web beacon or pixel tag is a tool that we may send to you to help us determine whether a page has been viewed. A
                                pixel tag is a type of web beacon embedded in an image on the website. This website may have pixel tags from vendors.
                            </li>
                        </ul>
                    </p>

                    <h5>Why We Use Tracking Tools</h5>

                    <p>
                        We use Tracking Tools for various reasons:
                        <ul>
                            <li>
                                To allow our Sites to work and help keep them safe and secure e.g. to load images and to allow you to log in securely or to store your password if
                                you have registered on our Site
                            </li>
                            <li>To improve the performance of our Sites by helping us analyze how they are used and understand which parts work well, and which don't</li>
                            <li>
                                To improve visitor experience on our Sites, for example we use cookies to recognize new or past customers, to remember the products you’ve put in
                                your basket, to make personalized recommendations and to personalize your experience
                            </li>
                            <li>
                                To observe your behaviors and browsing activities over time across multiple Sites or other platforms, to better understand the interests of our Site
                                visitors
                            </li>
                            <li>
                                To serve you with interest-based or targeted advertising (see below for more on interest-based advertising), to deliver relevant online advertising
                                to visitors on our Sites and third-party websites and to understand how effective our online advertising communications are
                            </li>
                        </ul>
                    </p>

                    <h5>How We Use Internet-Based Advertising</h5>

                    <p>
                        We and our partners display interest-based advertising using information gathered about you over time across multiple Sites. Interest-based advertising or
                        “online behavioral advertising” includes ads served to you after you leave a website, encouraging you to return. These ads may be served after you leave our
                        Sites, or after you leave the websites of third parties. They may also include ads we and our partners think are relevant based on your browsing habits or
                        online activities. These ads might be served on websites. We might serve these ads, or third parties may serve ads. They might be about our products or
                        other companies’ products.
                        <br />
                        <br />
                        To decide what is relevant to you, we and our partners use information you make available when you interact with us, our affiliates, and other third
                        parties. This information is gathered using the Tracking Tools described above. For example, we or our partners might look at your purchases or browsing
                        behaviors. We and our partners might look at these activities on our platforms or the platforms of others. <br />
                        <br />
                        We work with third parties who help gather this information. These third parties might link your name or email address to other information they collect,
                        such as past online and offline purchase or other online usage information.
                    </p>

                    <h5>What Tracking Tools We Use</h5>

                    <p>
                        We use cookies on our Site. Cookies can be classified in several ways.
                        <br />
                        Depending on who owns the cookie, they are classified as:
                        <ul>
                            <li>
                                <u>First-Party Cookies</u>: Cookies created by the website (domain) you are visiting.
                            </li>
                            <li>
                                <u>Third-Party Cookies</u>: Cookies created by domains other than the one you are visiting. Third-party cookies track across different sites to help
                                deliver services, and to retarget and show ads to you.
                            </li>
                        </ul>
                        Depending on the lifespan of the cookie, they are classified as:
                        <ul>
                            <li>
                                <u>Persistent Cookies</u>: Persistent cookies remain on your device over a longer period of time after you close your browser. This period of time
                                is defined by the owner of the cookie. Persistent cookies are deleted manually or automatically afterwards.: Cookies created by the website (domain)
                                you are visiting.
                            </li>
                            <li>
                                <u>Session Cookies</u>: Session cookies remain on your device only until you close your browser, then they are automatically deleted.Cookies created
                                by domains other than the one you are visiting. Third-party cookies track across different sites to help deliver services, and to retarget and show
                                ads to you.
                            </li>
                        </ul>
                        Depending on the purpose of the cookie, they are classified as:
                        <ul>
                            <li>
                                <u>Essential Cookies</u>: Essential cookies are technical cookies that are required for the operation of our websites, such as those that enable you
                                to log into secure areas, allow images to load, and orders to be processed. Without these cookies, our Sites can’t operate properly.
                            </li>
                            <li>
                                <u>Experience Cookies</u>: Experience cookies cannot track your browsing activity when you leave our Sites to browse other websites. Experience
                                cookies allow our Sites:
                                <ul>
                                    <li>
                                        To recognize and count the number of visitors and see how they move through the Site. This helps us to better understand and improve the way
                                        our Sites work.
                                    </li>
                                    <li>
                                        To remember the choices you make, providing you with better and more personalized features, such as comparing your choices to those of our
                                        other visitors. This helps us learn from those choices and provide you enhanced, more personalized features, such as product
                                        recommendations.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <u>Advertising Cookies</u>: Advertising cookies record your visits to our Sites, the pages you have visited and the links you have followed. We use
                                this information:
                                <ul>
                                    <li>To help make the advertising displayed on our Sites and certain third-party websites more relevant to your interests</li>
                                    <li>To limit the number of times that you see an ad</li>
                                    <li>To help measure the effectiveness of an advertising campaign</li>
                                </ul>
                            </li>
                        </ul>
                        To find out more about our specific cookies, please contact us at <a href="mailto:privacy@tyson.com">privacy@tyson.com</a>.
                    </p>

                    <h5>Your Rights Regarding Behavioral Advertising</h5>

                    <p>
                        The Digital Advertising Alliance’s Self-Regulatory Program for Online Behavioral Advertising (the “<strong>Program</strong>”) gives consumers the ability to
                        opt-out of the recording of their online behavior and of having such behavior used for advertising purposes. To opt out of having your online behavior
                        collected for advertising purposes, click <a href="http://www.aboutads.info/choices/">HERE</a>.<br />
                        <br />
                        The Digital Advertising Alliance also offers a tool for opting out of the collection of cross-app data on a mobile device for interest-based advertising. To
                        exercise choices for the companies participating in this tool, download the AppChoices app <a href="http://youradchoices.com/appchoices">HERE</a>.<br />
                        <br />
                        Some Tracking Tools we use do not participate in the Program. To learn more information about opting out of these Tracking Tools, please click on the
                        following:
                        <a href="http://www.ampush.com/privacy-policy/">AMP PLATFORM</a>, <a href="http://www.appnexus.com/en/company/platform-privacy-policy#choices">APPNEXUS</a>,
                        <a href="http://www.digilant.com/privacy-policy/">DILIGANT</a>, <a href="http://kenshoo.com/privacy-policy/">KENSHOO</a>,
                        <a href="http://www.evolvemediallc.com/privacy/">MARTINIMEDIA</a>, <a href="http://platform-cdn.sharethrough.com/privacy-policy">SHARETHROUGH</a>,
                        <a href="https://privacy.centro.net/">SITESCOUT</a>, <a href="http://www.spotxchange.com/privacy-policy/">SPOTXCHANGE</a>,
                        <a href="http://tremorvideodsp.com/privacy-policy.html">TREMOR MEDIA</a>, <a href="http://support.twitter.com/articles/20170514">TWITTER</a> and
                        <a href="http://www.xaxis.com/static/view/opt-out-confirmation">XAXIS</a>.<br />
                        <br />
                        To find out more about opt-out elections for third party advertising cookies available to users under the self-regulatory programs for online behavioral
                        advertising, please click:
                        <ul>
                            <li>
                                <a href="http://www.networkadvertising.org/choices/">NETWORK ADVERTISING INITIATIVE (NAI)</a>
                            </li>
                            <li>
                                <a href="https://tools.google.com/dlpage/gaoptout">GOOGLE ANALYTICS</a>
                            </li>
                        </ul>
                        If you don’t want your personal information to be used to allow third parties (e.g. Google, Facebook) to display ads or to personalize ads displayed to you,
                        you can change your settings by using the tools provided by their sites or through one of the above self-regulatory programs, if they participate in them.
                        <br />
                        <br />
                        Certain choices you make are both browser and device specific.
                    </p>

                    <h5>Your Right to Refuse Cookies</h5>

                    <p>
                        When visiting our Site for the first time on a device, you will see a cookies banner explaining our use of cookies. This banner gives you a settings option
                        for our Site. You can use this to turn certain cookies on or off. Please be aware that if you turn cookies off or disable tracking, some functionality or
                        parts of our sites may no longer be accessible.
                    </p>

                    <h5 id="HowtoControlTrackingTools">How to Control Certain Tracking Tools</h5>

                    <p>
                        Your browser may give you the ability to control cookies. Certain options you select are browser and device specific and may also depend on the type of
                        cookie. You may use your web browser to delete all cookies, block all cookies, allow all cookies, block third party cookies, clear all cookies when your
                        browser closes, install add-ons and plugins that extend browser functionality or open a private browsing or incognito session (this allows you to browse the
                        web without recording your browsing history or storing local data but has limitations in a privacy context). You will need to elect to opt out from each
                        device you want the opt out election to apply to.
                        <br />
                        <br />
                        <u>Do Not Track</u>: Some browsers have “do not track” features that allow you to tell a website not to track you. These features are not all uniform. We do
                        not currently respond to those signals. If you block cookies, certain features on our Sites may not work. If you block or reject cookies, only some of the
                        tracking described here will stop.
                        <br />
                        <br />
                        To control flash cookies, please click <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">HERE</a>. To
                        find out more about how to use your browser to control cookies, please click:
                        <ul>
                            <li>
                                <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">MICROSOFT EDGE COOKIES INFORMATION</a>
                            </li>
                            <li>
                                <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">INTERNET EXPLORER COOKIES INFORMATION</a>
                            </li>
                            <li>
                                <a href="https://support.google.com/chrome/answer/95647?hl=en-GB">CHROME COOKIES INFORMATION</a>
                            </li>
                            <li>
                                <a href="https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox">FIREFOX COOKIES INFORMATION</a>
                            </li>
                            <li>
                                Safari cookies information – <a href="https://support.apple.com/en-gb/HT201265">MOBILE DEVICES </a>
                            </li>
                            <li>
                                <a href="https://www.opera.com/help/tutorials/security/cookies/">OPERA COOKIES INFORMATION</a>
                            </li>
                        </ul>
                        You can also find more detailed about how you can manage cookies at the <a href="http://www.youronlinechoices.com/uk/">YOUR ONLINE CHOICES</a> website.
                    </p>

                    <h5>How to Contact Us</h5>

                    <p>
                        If you have any questions about our use of cookies, please contact please contact the Data Protection Officer at{" "}
                        <a href="mailto:privacy@tyson.com">privacy@tyson.com</a>. <br />
                        <br />
                    </p>

                    <em>Last updated: 10th March 2022</em>

                    <div>
                        <div>
                            <div>&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default PrivacyPolicy;
